<template>
  <div class="page-container procedure-page">
    <portal to="page-name">Serviços, Taxas e Produtos</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <div class="card-title">
          Serviços, Taxas e Produtos
          <span v-if="form.name"> - </span>
          <small class="text-secondary">{{ form.name }}</small>
        </div>
      </div>
      <div class="card-body">
        <st-tabs ref="procedure-tabs" @changed="changeTabs">
          <st-tab id="tab-procedure" name="Dados principais">
            <div class="columns">
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.tuss.code.$error}">
                <label class="form-label">TUSS</label>
                <dx-autocomplete
                  id="tuss"
                  ref="tussAutocomplete"
                  v-model="tuss"
                  :readonly="!!form.tuss.code"
                  :source="findTuss"
                  :custom-label="setTussLabel"
                  track-by="id"
                  @select="selectTussItem"
                  @blur="$v.form.tuss.code.$touch()"
                  :debounce="800"
                  placeholder="Nome ou código do TUSS"
                >
                  <button slot="action" @click="unsetTuss"
                          class="btn btn-action input-group-btn btn-icon"
                          :class="tuss && tuss.code ? 'btn-gray' : 'btn-neutral'"
                          tabindex="-1">
                    <fa-icon :icon="['fal', tuss && tuss.code ? 'times' : 'search']"></fa-icon>
                  </button>
                  <template v-slot="{ item }">
                    <div>{{ item.code | tuss }} - {{ item.name }} {{ item.description }}</div>
                  </template>
                </dx-autocomplete>
                <template v-if="$v.form.tuss.code.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.tuss.code.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-8 col-md-12 col-sm-12 form-group"
                   :class="{'has-error': $v.form.name.$error}">
                <label for="name" class="form-label">Nome</label>
                <input type="text" id="name"
                       v-model="form.name" class="form-input"
                       @blur="$v.form.name.$touch()"
                       placeholder="Nome" autocomplete="nope">
                <template v-if="$v.form.name.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.name.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-4 col-md-12 col-sm-12 form-group"
                   :class="{'has-error': $v.form.type.$error}">
                <label class="form-label">Tipo</label>
                <select id="type" class="form-select"
                        v-model="form.type" @blur="$v.form.type.$touch()">
                  <option value="">[Selecione]</option>
                  <option v-for="(text, value) in procedureType.types"
                          :value="value" :key="value">{{ text }}</option>
                </select>
                <template v-if="$v.form.type.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.type.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-2 col-md-3 col-sm-12 form-group" v-if="hasTime">
                <label class="form-label">Intervalo (min)</label>
                <dx-input-number class="form-input text-center"
                                 v-model="form.time"
                                 maxlength="3" />
              </div>
              <div class="column col-2 col-md-3 col-sm-12 form-group" v-if="hasValidity">
                <label class="form-label">Vigência (dias)</label>
                <dx-input-number v-model="form.validity"
                                 class="form-input text-center"
                                 maxlength="3" />
              </div>
              <div class="column col-8 col-md-6 col-sm-12 form-group"
                   :class="{'has-error': $v.form.specialtyCode.$error}"
                   v-if="hasSpecialty">
                <label class="form-label">Especialidade</label>
                <select class="form-select"
                        @blur="$v.form.specialtyCode.$touch()"
                        v-model="form.specialtyCode">
                  <option value="">[Selecione]</option>
                  <option v-for="(item, i) in specialties"
                          :value="item.code" :key="i">{{ item.name }}</option>
                </select>
                <template v-if="$v.form.specialtyCode.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.specialtyCode.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-3 col-md-6 col-sm-12 form-group"
                   v-if="form.type === 'exam'">
                <label class="form-label">Modalidade</label>
                <select class="form-select"
                        v-model="form.modality">
                  <option value="">[Selecione]</option>
                  <option v-for="(text, value, i) in modalities"
                          :value="value" :key="i">{{ value }} - {{ text }}</option>
                </select>
              </div>
              <div class="column col-3 col-md-6 col-sm-12 form-group"
                   v-if="form.type === 'exam'">
                <label class="form-label">Entrega (dias)</label>
                <dx-input-number v-model="form.medicalReportDays"
                                 placeholder="0"
                                 class="form-input text-center"
                                 maxlength="3"
                                 :disabled="form.type !== 'exam'" />
              </div>
              <div class="column col-2 col-md-6 col-sm-12 form-group"
                   v-if="form.type === 'exam'">
                <label class="form-label">Valor laudo</label>
                <dx-input-number class="form-input"
                                 v-model="form.medicalReportValue"
                                 :precision="2"
                                 maxlength="7" />
              </div>
              <div class="column col-3 col-md-12 col-sm-12 form-group"
                   :class="{'has-error': $v.form.inputUnit.$error}" v-if="hasProduct">
                <label class="form-label">Unidade de Entrada</label>
                <select id="input-unit" class="form-select"
                        @blur="$v.form.inputUnit.$touch()" v-model="form.inputUnit">
                  <option value="">[Selecione]</option>
                  <option v-for="(text, value) in measures.measure"
                          :value="value" :key="value">{{ text }}</option>
                </select>
                <template v-if="$v.form.inputUnit.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.inputUnit.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-3 col-md-12 col-sm-12 form-group"
                   :class="{'has-error': $v.form.outputUnit.$error}" v-if="hasProduct">
                <label class="form-label">Unidade de Saída</label>
                <select id="output-unit" class="form-select"
                        @blur="$v.form.outputUnit.$touch()" v-model="form.outputUnit">
                  <option value="">[Selecione]</option>
                  <option v-for="(text, value) in measures.measure"
                          :value="value" :key="value">{{ text }}</option>
                </select>
                <template v-if="$v.form.outputUnit.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.outputUnit.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-3 col-md-12 col-sm-12 form-group tooltip"
                   :class="{'has-error': $v.form.conversion.$error}" v-if="hasProduct"
                   :data-tooltip="`1 unidade de entrada representa ${
                     form.conversion} unidade(s) de saída`">
                <label class="form-label">Fator de conversão</label>
                <dx-input-number class="form-input text-center"
                                 @blur="$v.form.conversion.$touch()"
                                 v-model="form.conversion"/>
                <template v-if="$v.form.conversion.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.conversion.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-3 col-md-12 col-sm-12 form-group" v-if="hasProduct">
                <label class="form-label">Custo</label>
                <dx-input-number id="stock-cost" name="stock-cost"
                                 class="form-input text-right text-bold"
                                 v-model="form.stock.cost"
                                 :precision="2" readonly />
              </div>
              <div class="column col-4 col-md-12 col-sm-12 form-group"
                   :class="{'has-error': $v.form.group.id.$error}" v-if="hasProduct">
                <label class="form-label">Grupo</label>
                <select id="group" class="form-select"
                        @blur="$v.form.group.id.$touch()"
                        v-model="form.group.id">
                  <option value="">[Selecione]</option>
                  <option v-for="(item, i) in groups"
                          :value="item.id" :key="i">{{ item.name }}</option>
                </select>
                <template v-if="$v.form.group.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.group.id.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-4 col-md-12 col-sm-12 form-group"
                   :class="{'has-error': $v.form.family.id.$error}" v-if="hasProduct">
                <label class="form-label">Família</label>
                <select id="family" class="form-select"
                        @blur="$v.form.family.id.$touch()"
                        v-model="form.family.id">
                  <option value="">[Selecione]</option>
                  <option v-for="(item, i) in families"
                          :value="item.id" :key="i">{{ item.name }}</option>
                </select>
                <template v-if="$v.form.family.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.family.id.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group" v-if="hasProduct">
                <label class="form-label">Opme</label>
                <select id="opme" class="form-select"
                        v-model="form.opme">
                  <option value="false">Não</option>
                  <option value="true">Sim</option>
                </select>
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group" v-if="hasProduct">
                <label class="form-label">Controla lote</label>
                <select id="lot" class="form-select"
                        v-model="form.manageLot">
                  <option value="false">Não</option>
                  <option value="true">Sim</option>
                </select>
              </div>
              <div class="column col-4 col-md-12 col-sm-12 form-group" v-if="hasProduct">
                <label class="form-label">Mínimo</label>
                <dx-input-number v-model="form.stock.min" :precision="2"
                                 class="form-input text-right"/>
              </div>
              <div class="column col-4 col-md-12 col-sm-12 form-group" v-if="hasProduct">
                <label class="form-label">Compra</label>
                <dx-input-number v-model="form.stock.buy" :precision="2"
                                 class="form-input text-right"/>
              </div>
              <div class="column col-4 col-md-12 col-sm-12 form-group" v-if="hasProduct">
                <label class="form-label">Máximo</label>
                <dx-input-number v-model="form.stock.max" :precision="2"
                                 class="form-input text-right"/>
              </div>
              <div class="column col-12 form-group">
                <label class="form-label">Notas</label>
                <textarea id="notes" class="form-input" rows="4"
                          v-model="form.notes" autocomplete="none"></textarea>
              </div>
            </div>
          </st-tab>
          <st-tab id="tab-preparation" name="Preparo" v-if="hasPreparation">
            <div class="columns">
              <div class="column col-12 form-group preparation">
                <label class="form-label">Informações de preparo</label>
                <st-editor id="preparation" v-model="form.preparation"></st-editor>
              </div>
            </div>
          </st-tab>
          <st-tab id="tab-others" name="Outros" v-if="hasProduct">
            <div class="h6">Brasíndice</div>
            <div class="columns form-group">
              <div class="column col-5 col-md-12 col-sm-12 form-group">
                <label class="form-label">Nome</label>
                <dx-autocomplete
                  id="brasindice-name"
                  v-model="brasindice"
                  :readonly="!!form.brasindice.code"
                  :source="findBrasindice"
                  :custom-label="setBrasindiceName"
                  track-by="id"
                  @select="setBrasindice"
                  :debounce="800"
                  placeholder="Nome ou código do brasíndice"
                >
                  <button slot="action" @click="unsetBrasindice"
                          class="btn btn-action input-group-btn btn-icon"
                          :class="brasindice ? 'btn-gray' : 'btn-neutral'"
                          tabindex="-1">
                    <fa-icon :icon="['fal', brasindice ? 'times' : 'search']"></fa-icon>
                  </button>
                  <template v-slot="{ item }">
                    <div>
                      {{ item.name }}
                      {{ item.complement }}
                      <span v-if="item.factor">({{ item.factor }})</span>
                    </div>
                  </template>
                </dx-autocomplete>
              </div>
              <div class="column col-5 col-md-12 col-sm-12 form-group">
                <label for="brasindice-complement" class="form-label">Complemento</label>
                <input type="text" id="brasindice-complement" class="form-input"
                       v-model="form.brasindice.complement"
                       autocomplete="nope" readonly>
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group">
                <label for="brasindice-code" class="form-label">Código</label>
                <input type="text" id="brasindice-code"
                       class="form-input text-center"
                       v-model="form.brasindice.code"
                       autocomplete="nope" readonly>
              </div>
              <div class="column col-4 col-md-12 col-sm-12 form-group">
                <label for="brasindice-manufacturer" class="form-label">Fabricante</label>
                <input type="text" id="brasindice-manufacturer" class="form-input"
                       v-model="form.brasindice.manufacturer.name"
                       autocomplete="nope" readonly>
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group">
                <label for="brasindice-factor" class="form-label">Fator</label>
                <input type="text" id="brasindice-factor"
                       class="form-input text-center"
                       v-model="form.brasindice.factor"
                       autocomplete="nope" readonly>
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group">
                <label for="brasindice-quantity" class="form-label">Quantidade</label>
                <input type="text" id="brasindice-quantity"
                       class="form-input text-center"
                       v-model="form.brasindice.quantity"
                       autocomplete="nope" readonly>
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group">
                <label class="form-label">Valor Unitário</label>
                <dx-input-number class="form-input text-right"
                                 v-model="form.brasindice.value"
                                 :precision="2" readonly/>
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group">
                <label class="form-label">Valor Total</label>
                <dx-input-number class="form-input text-right"
                                 v-model="form.brasindice.total"
                                 :precision="2" readonly/>
              </div>
            </div>
            <div class="divider" />
            <div class="columns form-group">
              <div class="column col-2 col-sm-12 form-group">
                <label class="form-label">Código NCM</label>
                <input type="text" class="form-input text-right"
                       v-model="form.ncmCode" placeholder="000000"
                       v-mask-number />
              </div>
              <div class="column col-2 col-sm-12 form-group">
                <label class="form-label">Código EAN</label>
                <input type="text" class="form-input text-right"
                       v-model="form.eanCode" placeholder="000000"
                       v-mask-number />
              </div>
              <div class="column col-2 col-sm-12 form-group">
                <label class="form-label">Registro Anvisa</label>
                <input type="text" class="form-input text-right"
                       v-model="form.anvisaRegistration"
                       placeholder="000000" />
              </div>
              <div class="column col-3 col-sm-12 form-group">
                <label class="form-label">Referência no fabricante</label>
                <input type="text" class="form-input text-right"
                       v-model="form.manufacturerReference" placeholder="000000" />
              </div>
              <div class="column col-3 col-sm-12 form-group">
                <label class="form-label">AFE</label>
                <input type="text" class="form-input text-right tooltip"
                       data-tooltip="Autorização de funcionamento da empresa"
                       v-model="form.afe" placeholder="000000" />
              </div>
              <div class="column col-2 col-sm-12 form-group">
                <label class="form-label">Portaria</label>
                <select class="form-select" v-model="form.regulation">
                  <option value="">Selecione</option>
                  <option value="344A1">344-A1</option>
                  <option value="344A2">344-A2</option>
                  <option value="344B1">344-B1</option>
                  <option value="344C1">344-C1</option>
                  <option value="344C2">344-C2</option>
                  <option value="344C5">344-C5</option>
                </select>
              </div>
              <div class="column col-2 col-sm-12 form-group">
                <label class="form-label">Código DCB</label>
                <div class="input-group">
                  <input type="text" class="form-input text-right"
                         v-model="form.dcb.code" @change="findDcb" v-mask-number />
                  <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                          :class="{loading: dcbModal.loading}" @click="openDcbModal">
                    <fa-icon :icon="['fal', 'search']"></fa-icon>
                  </button>
                </div>
              </div>
              <div class="column form-group">
                <label class="form-label">Descrição</label>
                <input type="text" class="form-input"
                       v-model="form.dcb.name" readonly />
              </div>
            </div>
            <div class="divider" />
            <div class="columns form-group">
              <div class="column col-12 col-md-12 col-sm-12">
                <label class="form-label">Fornecedor(es)</label>
                <dx-autocomplete
                  id="provider"
                  ref="providerAutocomplete"
                  v-model="provider"
                  :source="findProvider"
                  :custom-label="setProviderLabel"
                  track-by="id"
                  @select="selectProviderItem"
                  :debounce="800"
                  placeholder="Informe o nome, código ou lote"
                >
                  <button slot="action"
                          class="btn btn-action input-group-btn btn-icon btn-neutral"
                          tabindex="-1">
                    <fa-icon :icon="['fal', 'search']"></fa-icon>
                  </button>
                  <template v-slot="{ item }">
                    <div>{{ item.name }}</div>
                  </template>
                </dx-autocomplete>
              </div>
            </div>
            <div class="empty mt-2" v-if="form.providers.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Fornecedores</p>
              <p class="empty-subtitle">
                Nenhum fornecedor adicionado. Utilize o campo acima.
              </p>
            </div>
            <table class="table table-striped table-hover" v-else>
              <thead>
              <tr>
                <th class="hide-sm" width="40px">#</th>
                <th>Nome</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in form.providers" :key="i">
                <td class="hide-sm">{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td class="text-right">
                  <button class="btn btn-sm btn-action btn-icon btn-error"
                          @click="removeProviderItem(item.id, i)">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </st-tab>
          <st-tab id="tab-insurances" name="Convênios">
            <plan-procedures
              v-if="form.id && selectedTab !== null"
              :origin="getOrigin"
            />
            <div class="empty mt-2" v-else>
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Convênios</p>
              <p class="empty-subtitle">
                Você precisa primeiro salvar o registro para depois vincular os convênios
              </p>
            </div>
          </st-tab>
        </st-tabs>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                v-if="!hideSave"
                @click="save" :disabled="saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
        <button class="btn btn-gray btn-icon btn-icon-left float-right"
                @click="openScheduleConfig" v-if="form.id">
          <fa-icon :icon="['fal', 'calendar-day']" />Gerenciar serviço nas agendas
        </button>
      </div>
    </div>
    <dx-modal title="Pesquisar DCB" id="dcb-modal" v-model="dcbModal.show">
      <div class="columns">
        <div class="column form-group">
          <div class="input-group">
            <input type="text" id="filter-search" name="filter-search" class="form-input"
                   v-model="dcbModal.search" placeholder="Informe o nome do produto..." v-focus>
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    :class="{loading: dcbModal.loading}" tabindex="-1">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
      </div>
      <div class="empty mt-2" v-if="dcbModal.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Lista de DCB`s</p>
        <p class="empty-subtitle">
          Nenhum registro encontrado.
        </p>
      </div>
      <table class="table table-hover table-striped" v-else>
        <thead>
        <tr>
          <th>Código</th>
          <th>Descrição</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in dcbModal.items" :key="i"
            @click="setDcb(item)">
          <td>{{ item.code }}</td>
          <td>{{ item.name }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="dcbModal.show = false">Sair</button>
      </template>
    </dx-modal>
    <modal-schedule-config
      v-if="scheduleConfigModal.show"
      :show="scheduleConfigModal.show"
      type="expense"
      :id="form.id"
      :name="form.name"
      @close="scheduleConfigModal.show = false"
    />
  </div>
</template>

<script>
import { modalities } from '@/data/expense-modalities';
import * as procedureType from 'src/data/expense-types';
import { required, minLength, minValue } from 'vuelidate/src/validators';
import ModalScheduleConfig from 'src/app/schedule/components/modals/Config.vue';
import { mergeFrom } from '@/helpers/object';
import * as measures from 'src/data/measure-units';
import * as productType from 'src/data/product-types';
import formMixin from '../../../mixins/form';
import tussFilter from '../../../filters/tuss';
import PlanProcedures from '../../insurance/components/modals/PlanProcedures.vue';

export default {
  mixins: [formMixin],
  components: {
    ModalScheduleConfig,
    PlanProcedures,
  },
  data() {
    return {
      procedureType,
      modalities,
      measures,
      productType,
      isNew: false,
      loading: false,
      saving: false,
      hideSave: false,
      debounceTimeout: null,
      tuss: null,
      brasindice: null,
      provider: null,
      selectedTab: null,
      specialties: [],
      families: [],
      groups: [],
      dcbModal: {
        loading: false,
        show: false,
        search: '',
        items: [],
      },
      scheduleConfigModal: {
        show: false,
      },
      form: this.blankForm(),
    };
  },
  watch: {
    'dcbModal.search': {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.loadDcb, 600);
      },
      deep: true,
    },
  },
  computed: {
    hasTime() {
      return this.form.type === 'consultation'
        || this.form.type === 'session'
        || this.form.type === 'surgery'
        || this.form.type === 'exam'
        || this.form.type === 'procedure'
        || this.form.type === 'evaluation';
    },
    hasValidity() {
      return this.form.type === 'consultation'
        || this.form.type === 'session'
        || this.form.type === 'surgery'
        || this.form.type === 'exam'
        || this.form.type === 'procedure'
        || this.form.type === 'evaluation';
    },
    hasSpecialty() {
      return this.form.type === 'consultation'
        || this.form.type === 'session'
        || this.form.type === 'evaluation';
    },
    hasProduct() {
      return this.form.type === 'medicine'
        || this.form.type === 'material';
    },
    hasPreparation() {
      return this.form.type === 'surgery'
        || this.form.type === 'exam'
        || this.form.type === 'procedure';
    },
    getOrigin() {
      return {
        type: 'expense',
        expense: {
          id: this.form.id,
          code: this.form.tuss.code || null,
          tuss: this.form.tuss,
          type: this.form.type,
          name: this.form.name,
          modality: this.form.modality,
          specialty: this.form.specialty,
          billAs: null,
        },
      };
    },
  },
  validations() {
    const rules = {
      form: {
        name: { required },
        type: { required },
        specialtyCode: {},
        inputUnit: {},
        outputUnit: {},
        conversion: {},
        tuss: {
          code: {},
        },
        family: { id: {} },
        group: { id: {} },
      },
    };

    if (this.hasSpecialty) {
      rules.form.specialtyCode = { required };
    }

    if (this.hasProduct) {
      rules.form.inputUnit = { required };
      rules.form.outputUnit = { required };
      rules.form.conversion = { required, minValue: minValue(0.01) };
      rules.form.tuss = {
        code: { minLength: minLength(8) },
      };
      rules.form.family = {
        id: { required },
      };
      rules.form.group = {
        id: { required },
      };
    }

    return rules;
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.loadSpecialties();
    this.loadGroups();
    this.loadFamilies();
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`/procedures/${this.form.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
          if (data.specialty && data.specialty.code) {
            this.form.specialtyCode = data.specialty.code;
          }
          if (data.tuss && data.tuss.code) {
            this.tuss = data.tuss;
          }
          if (data.brasindice && data.brasindice.code) {
            this.brasindice = {
              code: data.brasindice.code,
              name: data.brasindice.name,
              complement: data.brasindice.complement,
              factor: data.brasindice.factor,
            };
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadSpecialties() {
      const params = {
        limit: 0,
        isSpecialty: true,
      };

      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => {
          this.specialties = data.items;
        })
        .catch(() => {
          this.$toast.error('Erro ao carregar as especialidades');
        });
    },
    async save() {
      if (this.saving) {
        return;
      }

      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.$refs['procedure-tabs'].selectTab('tab-procedure');
        return;
      }

      const params = {
        name: this.form.name,
      };

      const { data: found } = await this.$http
        .get('/procedures', { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('Já existe um registro com este nome!', { type: 'error' });
        return;
      }

      this.saving = true;

      const service = this.clone(this.form);

      service.familyId = service.family.id;
      service.groupId = service.group.id;
      delete service.family;
      delete service.group;

      if (!service.dcb.code) {
        service.dcb = null;
      }

      if (!service.tuss.code) {
        service.tuss = null;
      }

      if (!service.brasindice.code) {
        service.brasindice = null;
      }

      const request = this.isNew
        ? this.$http.post('/procedures', service)
        : this.$http.put(`/procedures/${this.form.id}`, service);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/clinical/procedures/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    changeTabs({ tab }) {
      this.hideSave = tab.hash === 'tab-insurances';
      this.selectedTab = tab.hash;
    },
    setTussLabel(item) {
      return item ? `${tussFilter(item.code)} - ${item.name}` : '';
    },
    selectTussItem(item) {
      this.form.tuss.code = item.code;
      this.form.tuss.name = item.name;
      if (!this.form.name && item.name) {
        this.form.name = item.name;
      }
    },
    findTuss(text) {
      const value = text.trim();

      const params = {
        search: value,
        types: 'material,medicine,service,procedure',
      };

      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    unsetTuss() {
      this.$refs.tussAutocomplete.searchText = null;
      this.form.tuss = {
        code: '',
        name: '',
      };
      this.tuss = null;
    },
    findDcb() {
      if (!this.form.dcb.code) {
        this.form.dcb.name = '';
        return null;
      }
      if (this.form.dcb.code.length < 5) {
        this.$toast.show('Código inválido!', { type: 'error' });
        this.form.dcb.code = '';
        this.form.dcb.name = '';
        return null;
      }

      this.dcbModal.loading = true;

      return this.$http
        .get(`/dcbs/${this.form.dcb.code}`)
        .then(({ data }) => {
          if (data) {
            this.form.dcb = data;
          } else {
            this.$toast.show('DCB não encontrado!', { type: 'error' });
            this.form.dcb.code = '';
            this.form.dcb.name = '';
          }
        })
        .catch(() => {})
        .finally(() => {
          this.dcbModal.loading = false;
        });
    },
    openDcbModal() {
      this.dcbModal.search = '';
      this.dcbModal.items = [];
      this.dcbModal.show = true;
    },
    loadDcb() {
      if (!this.dcbModal.search) {
        return null;
      }

      this.dcbModal.loading = true;

      const params = {
        search: this.dcbModal.search,
      };

      return this.$http
        .get('/dcbs/', { params })
        .then(({ data }) => {
          this.dcbModal.items = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.dcbModal.loading = false;
        });
    },
    setDcb(item) {
      this.form.dcb = item;
      this.dcbModal.show = false;
    },
    findBrasindice(search) {
      const params = {
        search,
      };

      return this.$http.get('/brasindice', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setBrasindice(data) {
      this.form.brasindice.code = data ? data.code : null;
      this.form.brasindice.name = data ? data.name : null;
      this.form.brasindice.complement = data ? data.complement : null;
      this.form.brasindice.tussCode = data ? data.tussCode : null;
      this.form.brasindice.manufacturer = data ? data.manufacturer : {};
      this.form.brasindice.factor = data ? data.factor : null;
      this.form.brasindice.quantity = data ? data.quantity : null;
      this.form.brasindice.value = data ? data.value : null;
      this.form.brasindice.total = data ? data.total : null;
    },
    unsetBrasindice() {
      this.setBrasindice(null);
      this.brasindice = null;
    },
    setBrasindiceName(item) {
      return `${item.name} ${item.complement} (${item.factor})`;
    },
    loadGroups() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.$http.get('/product-groups', { params })
        .then(({ data }) => {
          this.groups = data.items;
        })
        .catch(() => {});
    },
    loadFamilies() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.$http.get('/product-families', { params })
        .then(({ data }) => {
          this.families = data.items;
        })
        .catch(() => {});
    },
    findProvider(text) {
      const value = text.trim();

      const params = {
        search: value,
        limit: 10,
      };

      return this.$http.get('/providers', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    setProviderLabel(item) {
      return item.name || null;
    },
    selectProviderItem(item) {
      const found = this.form.providers.find(({ id }) => id === item.id);

      if (!found) {
        this.form.providers.push({
          id: item.id,
          name: item.name,
          email: item.email,
        });
      }

      this.provider = null;
    },
    removeProviderItem(id, i) {
      this.form.providers.splice(i, 1);
    },
    openScheduleConfig() {
      this.scheduleConfigModal.show = true;
    },
    blankForm() {
      return {
        id: '',
        name: '',
        complement: '',
        type: '',
        modality: '',
        time: '',
        tuss: {
          code: '',
          name: '',
        },
        opme: false,
        manageLot: false,
        inputUnit: '',
        outputUnit: '',
        conversion: '1',
        group: {
          id: '',
          name: '',
        },
        family: {
          id: '',
          name: '',
        },
        preparation: '',
        medicalReportDays: '',
        medicalReportValue: '',
        validity: '',
        specialtyCode: '',
        specialty: {
          code: '',
          name: '',
        },
        stock: {
          min: '',
          buy: '',
          max: '',
          cost: '',
        },
        brasindice: {
          code: '',
          name: '',
          complement: '',
          tussCode: '',
          manufacturer: {
            code: '',
            name: '',
          },
          factor: '',
          quantity: '',
          value: '',
          total: '',
        },
        ncmCode: '',
        eanCode: '',
        regulation: '',
        anvisaRegistration: '',
        manufacturerReference: '',
        afe: '',
        dcb: {
          code: '',
          name: '',
        },
        providers: [],
        notes: '',
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.procedure-page {
  .preparation {
    .document-editor {
      .document-editor-body {
        .ck.ck-content.ck-editor__editable {
          min-height: 20rem;
        }
      }
    }
  }
}
</style>
