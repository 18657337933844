export const measure = {
  AMP: 'AMP',
  BUI: 'BUI',
  BG: 'BG',
  BOLS: 'BOLS',
  CX: 'CX',
  CAP: 'CAP',
  CARP: 'CARP',
  COM: 'COM',
  DOSE: 'DOSE',
  DRG: 'DRG',
  ENV: 'ENV',
  FLAC: 'FLAC',
  FR: 'FR',
  FA: 'FA',
  GAL: 'GAL',
  GLOB: 'GLOB',
  GTS: 'GTS',
  G: 'G',
  L: 'L',
  MCG: 'MCG',
  MUI: 'MUI',
  MG: 'MG',
  ML: 'ML',
  OVL: 'OVL',
  PAS: 'PAS',
  LT: 'LT',
  PER: 'PER',
  PIL: 'PIL',
  PT: 'PT',
  KG: 'KG',
  SER: 'SER',
  SUP: 'SUP',
  TABLE: 'TABLE',
  TUB: 'TUB',
  TB: 'TB',
  UN: 'UN',
  UI: 'UI',
  CM: 'CM',
  CONJ: 'CONJ',
  KIT: 'KIT',
  MC: 'MÇ',
  M: 'M',
  PC: 'PC',
  PIECE: 'PÇ',
  RL: 'RL',
  GY: 'GY',
  CGY: 'CGY',
  PAR: 'PAR',
  ADES: 'ADES',
  COM_EFEV: 'COM EFEV',
  COM_MST: 'COM MST',
  LINEAR_METER: 'M',
  SQUARE_METER: 'M²',
  CUBIC_METER: 'M³',
  MG_WEIGHT: 'MG/PESO',
  MG_SQUARE_METER: 'MG/M²',
  CAL: 'CAL',
  UI_: 'UI/M²',
  UI_ML: 'UI_ML',
  CM_CUBIC_METER: 'CM³',
};

export function getName(value) {
  return measure[value];
}
